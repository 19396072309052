import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Payment.css';

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { serviceRequest, formData } = location.state;
  const [paymentMethod, setPaymentMethod] = useState('mtn');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [transactionId, setTransactionId] = useState(null);
  const [checkingStatus, setCheckingStatus] = useState(false);

  useEffect(() => {
    let statusCheck;
    if (transactionId && checkingStatus) {
      statusCheck = setInterval(async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/payment/status/${transactionId}`
          );
          
          if (response.data.status === 'completed') {
            clearInterval(statusCheck);
            setCheckingStatus(false);
            navigate('/success', {
              state: {
                message: "Payment successful! Your project is now in development.",
                serviceRequest,
                formData
              }
            });
          }
        } catch (err) {
          console.error('Error checking payment status:', err);
        }
      }, 3000); // Check every 3 seconds
    }

    return () => {
      if (statusCheck) clearInterval(statusCheck);
    };
  }, [transactionId, checkingStatus, navigate, serviceRequest, formData]);

  const handlePayment = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/payment/initiate`,
        {
          serviceRequestId: serviceRequest._id,
          paymentMethod,
          phoneNumber
        }
      );

      setTransactionId(response.data.transactionId);
      setCheckingStatus(true);
      
    } catch (err) {
      setError(err.response?.data?.message || 'Payment failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="payment-container">
      <h2>Payment Details</h2>
      <div className="payment-summary">
        <h4>Order Summary</h4>
        <p>Service: {serviceRequest.service}</p>
        <p>Tier: {serviceRequest.tier}</p>
        <p>Amount: {serviceRequest.price}</p>
      </div>

      {error && <Alert variant="danger">{error}</Alert>}
      
      {checkingStatus ? (
        <div className="status-checking">
          <Spinner animation="border" variant="primary" />
          <p>Please check your phone and approve the payment request...</p>
        </div>
      ) : (
        <Form onSubmit={handlePayment}>
          <Form.Group className="mb-3">
            <Form.Label>Payment Method</Form.Label>
            <Form.Select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="mtn">MTN Mobile Money</option>
              <option value="orange">Orange Money</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Enter your mobile money number"
              required
            />
          </Form.Group>

          <Button 
            variant="primary" 
            type="submit" 
            disabled={loading || checkingStatus}
            className="w-100"
          >
            {loading ? 'Processing...' : 'Pay Now'}
          </Button>
        </Form>
      )}
    </Container>
  );
};

export default Payment; 