import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import './ServiceConfirmation.css';

const ServiceConfirmation = () => {
  const location = useLocation();
  const { message, serviceRequest } = location.state || {};

  return (
    <Container className="confirmation-container">
      <div className="confirmation-content">
        <h2>Thank You!</h2>
        <div className="confirmation-message">
          <p>{message || "Your request has been received."}</p>
        </div>

        {serviceRequest && (
          <div className="request-summary">
            <h3>Request Summary</h3>
            <p><strong>Service:</strong> {serviceRequest.service}</p>
            <p><strong>Tier:</strong> {serviceRequest.tier}</p>
            <div className="support-contact">
              <h4>Need Immediate Assistance?</h4>
              <p>Contact our support team:</p>
              <a 
                href="https://wa.me/237677699999" 
                className="whatsapp-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                WhatsApp Support
              </a>
            </div>
          </div>
        )}

        <div className="action-buttons">
          <Link to="/">
            <Button variant="primary">Return to Home</Button>
          </Link>
          <Link to="/services">
            <Button variant="outline-primary">Browse More Services</Button>
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default ServiceConfirmation; 