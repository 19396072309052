// src/App.js

import React from 'react';
import { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Services from "./components/Services/Services";
import Blog from "./components/Blog/Blog";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import RegistrationForm from "./components/Registration/RegistrationForm";
import SuccessPage from "./components/Registration/SuccessPage";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { HelmetProvider } from 'react-helmet-async';
import ServiceDetailsForm from "./components/ServiceDetails/ServiceDetailsForm";
import Payment from "./components/Payment/Payment";
import ServiceConfirmation from "./components/ServiceConfirmation/ServiceConfirmation";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <Preloader load={load} />
        <div className="App" id={load ? "no-scroll" : "scroll"}>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/project" element={<Projects />} />
            <Route path="/about" element={<About />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/register" element={<RegistrationForm />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/service-details" element={<ServiceDetailsForm />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/service-confirmation" element={<ServiceConfirmation />} />
            <Route path="*" element={<Navigate to="/"/>} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
