export const blogPlaceholders = {
  techStack: "https://via.placeholder.com/800x600/2d2d2d/ffffff?text=Tech+Stack",
  flutterVsReact: "https://via.placeholder.com/800x600/2d2d2d/ffffff?text=Flutter+vs+React",
  mobileApp: "https://via.placeholder.com/800x600/2d2d2d/ffffff?text=Mobile+App",
  techStack1: "https://via.placeholder.com/800x600/2d2d2d/ffffff?text=Tech+Stack+1",
  techStack2: "https://via.placeholder.com/800x600/2d2d2d/ffffff?text=Tech+Stack+2",
  flutter1: "https://via.placeholder.com/800x600/2d2d2d/ffffff?text=Flutter+1",
  flutter2: "https://via.placeholder.com/800x600/2d2d2d/ffffff?text=Flutter+2",
  mobileApp1: "https://via.placeholder.com/800x600/2d2d2d/ffffff?text=Mobile+App+1",
  mobileApp2: "https://via.placeholder.com/800x600/2d2d2d/ffffff?text=Mobile+App+2"
}; 