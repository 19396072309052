import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import './ServiceDetails.css';

const ServiceDetailsForm = ({ serviceRequest, onCancel }) => {
  const [formData, setFormData] = useState({
    websiteType: '',
    features: [],
    designPreferences: '',
    referenceLinks: '',
    platform: '',
    appType: '',
    designType: '',
    brandColors: '',
    stylePreferences: [],
    projectTimeline: 'standard',
    additionalNotes: ''
  });
  const [files, setFiles] = useState({});
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    try {
      // Validate serviceRequest
      if (!serviceRequest?._id) {
        throw new Error('Invalid service request');
      }

      // File size validation
      const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
      for (const [key, file] of Object.entries(files)) {
        if (file && file.size > MAX_FILE_SIZE) {
          throw new Error(`File ${file.name} is too large. Maximum size is 5MB`);
        }
      }

      // Upload files
      const fileUrls = {};
      for (const [key, file] of Object.entries(files)) {
        if (file) {
          const formData = new FormData();
          formData.append('file', file);
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/upload`, 
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
            );
            fileUrls[key] = response.data.url;
          } catch (uploadError) {
            console.error(`Error uploading file ${key}:`, uploadError);
            throw new Error(`Failed to upload ${file.name}`);
          }
        }
      }

      // Update service request
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/service-request/${serviceRequest._id}`,
        {
          ...formData,
          files: fileUrls
        }
      );

      alert('Thank you for providing additional details! We will contact you soon.');
      window.location.href = '/';
    } catch (error) {
      console.error('Error updating service request:', error);
      setError(error.message || 'Error saving details. Please try again or contact support.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileChange = (e, field) => {
    setFiles({
      ...files,
      [field]: e.target.files[0]
    });
  };

  const getServiceSpecificFields = () => {
    switch(serviceRequest.service) {
      case "Web Design & Development":
        return (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Website Type</Form.Label>
              <Form.Select 
                value={formData.websiteType}
                onChange={(e) => setFormData({...formData, websiteType: e.target.value})}
              >
                <option value="">Select type</option>
                <option value="business">Business Website</option>
                <option value="ecommerce">E-Commerce</option>
                <option value="portfolio">Portfolio</option>
                <option value="blog">Blog</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Features Needed</Form.Label>
              {[
                'Contact Form',
                'Blog Section',
                'Newsletter',
                'Social Media Integration',
                'User Authentication',
                'Search Functionality',
                'Multi-language Support',
                'Custom Admin Panel'
              ].map(feature => (
                <Form.Check
                  key={feature}
                  type="checkbox"
                  label={feature}
                  checked={formData.features.includes(feature)}
                  onChange={(e) => {
                    const features = [...formData.features];
                    if (e.target.checked) {
                      features.push(feature);
                    } else {
                      const index = features.indexOf(feature);
                      if (index > -1) features.splice(index, 1);
                    }
                    setFormData({...formData, features});
                  }}
                />
              ))}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Reference Links</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                value={formData.referenceLinks}
                onChange={(e) => setFormData({...formData, referenceLinks: e.target.value})}
                placeholder="Links to websites you like..."
              />
            </Form.Group>
          </>
        );

      case "Mobile App Development":
        return (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Platform</Form.Label>
              <Form.Select
                value={formData.platform}
                onChange={(e) => setFormData({...formData, platform: e.target.value})}
              >
                <option value="">Select platform</option>
                <option value="ios">iOS</option>
                <option value="android">Android</option>
                <option value="both">Both (Cross-platform)</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>App Type</Form.Label>
              <Form.Select
                value={formData.appType}
                onChange={(e) => setFormData({...formData, appType: e.target.value})}
              >
                <option value="">Select type</option>
                <option value="business">Business App</option>
                <option value="social">Social Media</option>
                <option value="utility">Utility</option>
                <option value="game">Game</option>
                <option value="other">Other</option>
              </Form.Select>
            </Form.Group>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="service-details-form">
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      {getServiceSpecificFields()}

      <Form.Group className="mb-3">
        <Form.Label>Additional Notes</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={formData.additionalNotes}
          onChange={(e) => setFormData({...formData, additionalNotes: e.target.value})}
          placeholder="Any other details you'd like to share..."
        />
      </Form.Group>

      <div className="form-buttons">
        <Button 
          variant="primary" 
          type="submit" 
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Details'}
        </Button>
        <Button 
          variant="outline-secondary" 
          onClick={onCancel}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};

export default ServiceDetailsForm; 